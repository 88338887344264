<template>
  <v-container fluid>
    <div class="from-content">
      <v-form class="from" ref="form">
        <v-row>
          <v-col cols="7" md="6">
            <v-select
                dense
                v-model="data.contact_type_id"
                outlined
                label="ContactType"
                :hint="`${server_errors.contact_type_id}`"
                persistent-hint
                :items="ContactType"
                item-value="id"
                item-text="name"
            >
            </v-select>
          </v-col>

          <v-col cols="5" md="6">
            <v-text-field
                dense
                outlined
                label="ContactName"
                v-model="data.value"
                :hint="`${server_errors.value}`"
                persistent-hint
            >
            </v-text-field>
          </v-col>
          <v-col co="12" md="12">
            <v-btn class="btn-save-change" @click="ValidateForm" :loading="btnLoading">
              Save Change
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>

  </v-container>
</template>

<script>
export default {
  name: "createContact",
  data() {
    return {
      btnLoading: false,
      ContactType: [],
      data: {
        contact_type_id: "",
        value: "",
      },
      server_errors: {
        contact_type_id: "",
        value: "",
      }
    }
  },
  methods: {
    ValidateForm() {
      if (this.$refs.form.validate()) {
        this.saveChange();
        this.btnLoading = true
      }
    },
    saveChange() {
      this.$axios.post(`employee/contact`,this.data).then((res) => {
        if (res.data.code === 200) {
          setTimeout(() => {
            this.$notification.OpenNotification_AddItem_OnSuccess('top-right', 'primary', 3000);
          }, 300)
        }

        this.$router.push({name: "contact.index"})
        this.btnLoading = false;
      }).catch((error) => {
        if (error.response.status === 422) {
          const obj = error.response.data.errors;
          for (let [key, value] of Object.entries(obj)) {
            this.server_errors[key] = value[0];
          }
        }
        this.btnLoading = false;
      })
    },

    getContactType() {
      this.$axios.get(`employee/contact-type`).then((res) => {
        this.ContactType = res.data.data;
        console.log(this.ContactType)
      }).catch(() => {
      })
    }
  },
  created() {
    this.getContactType();
  }
}
</script>

<style scoped lang="scss">
.from-content {
  width: 100%;
  height: 550px;
  display: flex;
  justify-content: center;
  align-items: center;
  // background-color: red;
  padding: 40px 50px 200px;

  .from {
    width: 600px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
